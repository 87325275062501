<section>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title text-uppercase">
                        Due Billing
                    </h4>
                </div>
                <hr>
                <div class="card-body">
                    <form [formGroup]="patientBillingForm" (ngSubmit)="submitPatientBillingForm()">

                        <div *ngIf="caseSheetData" class="case-com-content p-0">
                            <h4>Patient Details</h4>
                            <div *ngIf="caseSheetData" class="row existingInformation-data">
                                <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Patient Name</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{patient_info.patient_name}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Age</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{patient_info.age}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Date</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{caseSheetData.visiting_date | date: 'dd-MM-yyyy'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Address</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <address class="txt-ovrflow-wrap">
                                                        {{patient_info.house_no}}, {{patient_info.street}},
                                                        {{patient_info.city}}, {{patient_info.district}},
                                                        {{patient_info.state_name}}
                                                    </address>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Treatment Request For</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{caseSheetData.treatment_for}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Patient ID</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{caseSheetData.patient_unique_id}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Sex</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{caseSheetData.gender == 'm' ? 'Male' :
                                                        caseSheetData.gender == 'f' ? 'Female' : 'Others'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Came Through</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{patient_info.how_came_know}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Pincode</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{patient_info.pincode}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Occupation</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{patient_info.occupation}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Mobile</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{patient_info.mobile}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Phone</label>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <p>{{patient_info.alt_mobile ? patient_info.alt_mobile : '-'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div *ngIf="caseSheetData" class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Treatment Plan</h4>
                                    <div class="row existingInformation-data">
                                        <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <label>No. of Days for Medicines <b>(Doctor)</b></label>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <p>{{caseSheetData.no_of_days ? caseSheetData.no_of_days :
                                                                '0'}} days.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-4">
                                                            <label>No. of Days for Medicines <b>(PRO)</b></label>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <input type="text" class="form-control"
                                                                formControlName="no_of_days_medicine"
                                                                (keypress)="allowNumbersOnly($event)"
                                                                [ngClass]="{ 'is-invalid error': errorMessages.no_of_days_medicine && no_of_days_medicine.errors && no_of_days_medicine.touched }">
                                                            <div
                                                                *ngFor="let error of errorMessages.no_of_days_medicine">
                                                                <ng-container
                                                                    *ngIf="no_of_days_medicine.hasError(error.type) && (no_of_days_medicine.touched)">
                                                                    <small class="text-danger">{{error.message}}</small>
                                                                </ng-container>
                                                            </div>
                                                            <div
                                                                *ngIf="patientBillingForm.controls.no_of_days_medicine.hasError('serverValidationError')">
                                                                <small class="text-danger">{{
                                                                    backendValidations['no_of_days_medicine']
                                                                    }}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="billingsData[0]['due']==0">
                            <div class="col-lg-4 col-md-6 col-xl-4 col-12">
                                <div class="form-group">
                                    <label>Package Status</label>
                                    <ng-select [items]="packageStatus" bindValue="value" bindLabel="title"
                                        placeholder="Select" formControlName="package_status"
                                        [ngClass]="{ 'is-invalid error': errorMessages.package_status && package_status.errors && package_status.touched }">
                                    </ng-select>
                                    <div *ngFor="let error of errorMessages.package_status">
                                        <ng-container
                                            *ngIf="package_status.hasError(error.type) && (package_status.touched)">
                                            <small class="text-danger">{{error.message}}</small>
                                        </ng-container>
                                    </div>
                                    <div
                                        *ngIf="patientBillingForm.controls.package_status.hasError('serverValidationError')">
                                        <small class="text-danger">{{ backendValidations['package_status']
                                            }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-xl-4 col-12">
                                <div class="form-group">
                                    <label>Plan</label>
                                    <ng-select bindLabel="value" placeholder="Select" formControlName="plan"
                                        [ngClass]="{ 'is-invalid error': errorMessages.plan && plan.errors && plan.touched }">
                                        <ng-option *ngFor='let in of counter(120);let i = index' [value]="i+1">{{i+1}}
                                            <span>{{i+1 == '1' ? 'month' :
                                                'months'}}</span></ng-option>
                                    </ng-select>
                                    <div *ngFor="let error of errorMessages.plan">
                                        <ng-container *ngIf="plan.hasError(error.type) && (plan.touched)">
                                            <small class="text-danger">{{error.message}}</small>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="patientBillingForm.controls.plan.hasError('serverValidationError')">
                                        <small class="text-danger">{{ backendValidations['plan']
                                            }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-xl-4 col-12 mt-2">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6">
                                                <label><b>Date of Expiry :</b></label>
                                            </div>
                                            <div class="col-lg-8 col-md-6">
                                                <p *ngIf="date_of_expiry">{{date_of_expiry |
                                                    date:'medium'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="package_section">
                            <div class="row">
                                <div class="col-12">
                                    <div class="case-com-content pb-50">
                                        <h4>Payment Options</h4>
                                        <div class="form-group">
                                            <div class="demo-inline-spacing">
                                                <div class="custom-control custom-radio mt-0"
                                                    *ngFor="let p of paymentOptions">
                                                    <input type="radio" id="{{p.value}}" value="{{p.id}}"
                                                        name="paymentOptionsEntry" formControlName="paymentOptionsEntry"
                                                        value="{{p.id}}" (change)="selectPaymentType(p.id)"
                                                        class="custom-control-input" />
                                                    <label class="custom-control-label"
                                                        for="{{p.value}}">{{p.value}}</label>
                                                </div>
                                            </div>
                                            <div *ngIf="payment_Options.invalid && payment_Options.touched">
                                                <div class="text-danger" *ngIf="payment_Options.errors?.required">Please
                                                    select payment option.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Amount section -->
                            <div class="row" *ngIf="paymentType">
                                <div class="col-12">
                                    <div class="case-com-content pt-0 pb-0">
                                        <h4>Amount</h4>
                                        <div class="row existingInformation-data">
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 col-md-3">
                                                                <label>Amount</label>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <p>{{ billingsData[0]['total_amount'] }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 col-md-3">
                                                                <label>Present Due</label>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                                <p>{{billingsData[0]['due']}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-12" *ngIf="billingsData[0]['due']==0">
                                                <div class="form-group">
                                                            <label>Increase Amount</label>
                                                            <input (input)="calculateGstAmount()" (keypress)="allowNumbersOnly($event)"
                                                                formControlName="amount" type="text" class="form-control"
                                                                [ngClass]="{ 'is-invalid error': errorMessages.amount && amount.errors && amount.touched }">
                                                            <div *ngFor="let error of errorMessages.amount">
                                                                <ng-container
                                                                    *ngIf="amount.hasError(error.type) && (amount.touched)">
                                                                    <small class="text-danger">{{error.message}}</small>
                                                                </ng-container>
                                                            </div>
                                                            <div
                                                                *ngIf="patientBillingForm.controls.amount.hasError('serverValidationError')">
                                                                <small class="text-danger">{{
                                                                    backendValidations['amount'] }}</small>
                                                            </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12" *ngIf="billingsData[0]['due']==0">
                                                <div class="form-group">
                                                    <label>GST Amount</label>
                                                    <input readonly
                                                        formControlName="gst_amount" type="text" class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.gst_amount && gst_amount.errors && gst_amount.touched }">
                                                    <div *ngFor="let error of errorMessages.gst_amount">
                                                        <ng-container
                                                            *ngIf="gst_amount.hasError(error.type) && (gst_amount.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.gst_amount.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['gst_amount'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12" *ngIf="billingsData[0]['due']==0">
                                                <label for="">Total Amount</label>
                                                    <input readonly
                                                        formControlName="increase_amount" type="text"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.increase_amount && increase_amount.errors && increase_amount.touched }"
                                                        type="text" class="form-control">
                                                    <div *ngFor="let error of errorMessages.increase_amount">
                                                        <ng-container
                                                            *ngIf="increase_amount.hasError(error.type) && (increase_amount.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.increase_amount.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['increase_amount'] }}</small>
                                                    </div>
                                            </div>
                                            
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label for="">Received Amount</label>
                                                    <input (input)="calculateDueAmount()"
                                                        (keypress)="allowNumbersOnly($event)"
                                                        formControlName="received_amount" type="text"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.received_amount && received_amount.errors && received_amount.touched }"
                                                        type="text" class="form-control">
                                                    <div *ngFor="let error of errorMessages.received_amount">
                                                        <ng-container
                                                            *ngIf="received_amount.hasError(error.type) && (received_amount.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.received_amount.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['received_amount'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label for="">Discount</label>
                                                    <input (input)="calculateDueAmount()"
                                                        (keypress)="allowNumbersOnly($event)" formControlName="discount"
                                                        type="text" class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.discount && discount.errors && discount.touched }"
                                                        type="text" class="form-control">
                                                    <div *ngFor="let error of errorMessages.discount">
                                                        <ng-container
                                                            *ngIf="discount.hasError(error.type) && (discount.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.discount.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['discount'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label for="">Due Amount</label>
                                                    <input readonly formControlName="due_amount" type="text"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.due_amount && due_amount.errors && due_amount.touched }">
                                                    <div *ngFor="let error of errorMessages.due_amount">
                                                        <ng-container
                                                            *ngIf="due_amount.hasError(error.type) && (due_amount.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.due_amount.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['due_amount'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <!--/ Amount section -->

                            <!-- Cheque details -->
                            <div class="row mt-1" *ngIf="checkDetails">
                                <div class="col-12">
                                    <div class="case-com-content pt-0 pb-50">
                                        <h4>Cheque Details</h4>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Cheque No</label>
                                                    <input formControlName="cheque_no" type="text" class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.cheque_no && cheque_no.errors && cheque_no.touched }">
                                                    <div *ngFor="let error of errorMessages.cheque_no">
                                                        <ng-container
                                                            *ngIf="cheque_no.hasError(error.type) && (cheque_no.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.cheque_no.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['cheque_no'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Bank Name</label>
                                                    <input formControlName="bank_name" type="text" class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.bank_name && bank_name.errors && bank_name.touched }">
                                                    <div *ngFor="let error of errorMessages.cheque_no">
                                                        <ng-container
                                                            *ngIf="cheque_no.hasError(error.type) && (cheque_no.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.cheque_no.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['cheque_no'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Cheque Date</label>
                                                    <input formControlName="cheque_date" type="date"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.cheque_date && cheque_date.errors && cheque_date.touched }">
                                                    <div *ngFor="let error of errorMessages.cheque_date">
                                                        <ng-container
                                                            *ngIf="cheque_date.hasError(error.type) && (cheque_date.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.cheque_date.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['cheque_date'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--/ Cheque details -->

                            <!-- Credit card -->
                            <div class="row mt-1" *ngIf="creditCard">
                                <div class="col-12">
                                    <div class="case-com-content pt-0 pb-50">
                                        <h4>Credit Card</h4>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Invoice No</label>
                                                    <input formControlName="invoice_no" type="text" class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.invoice_no && invoice_no.errors && invoice_no.touched }">
                                                    <div *ngFor="let error of errorMessages.invoice_no">
                                                        <ng-container
                                                            *ngIf="invoice_no.hasError(error.type) && (invoice_no.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.invoice_no.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['invoice_no'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Card Type</label>
                                                    <input formControlName="card_type" type="text" class="form-control"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.card_type && card_type.errors && card_type.touched }">
                                                    <div *ngFor="let error of errorMessages.card_type">
                                                        <ng-container
                                                            *ngIf="card_type.hasError(error.type) && (card_type.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.card_type.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['card_type'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--/ Credit card -->

                            <!-- Online -->
                            <div class="row mt-1" *ngIf="online">
                                <div class="col-12">
                                    <div class="case-com-content pt-0 pb-50">
                                        <h4>Online Details</h4>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Bank Name</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="ol_bank_name"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.ol_bank_name && ol_bank_name.errors && ol_bank_name.touched }">
                                                    <div *ngFor="let error of errorMessages.ol_bank_name">
                                                        <ng-container
                                                            *ngIf="ol_bank_name.hasError(error.type) && (ol_bank_name.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.ol_bank_name.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['ol_bank_name'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Account Number</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="allowNumbersOnly($event)"
                                                        formControlName="account_number"
                                                        [ngClass]="{ 'is-invalid error': errorMessages.account_number && account_number.errors && account_number.touched }">
                                                    <div *ngFor="let error of errorMessages.account_number">
                                                        <ng-container
                                                            *ngIf="account_number.hasError(error.type) && (account_number.touched)">
                                                            <small class="text-danger">{{error.message}}</small>
                                                        </ng-container>
                                                    </div>
                                                    <div
                                                        *ngIf="patientBillingForm.controls.account_number.hasError('serverValidationError')">
                                                        <small class="text-danger">{{
                                                            backendValidations['account_number'] }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--/ Online -->

                            <div class="row mt-1">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" type="submit">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>