import { CoreMenu } from "@core/types";

export const gmMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "section",
    icon: "home",
    children: [
      {
        id: "download",
        title: "Download",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/download-list",
      }, {
        id: "transfer",
        title: "Transfer",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/transfer",
      }, {
        id: "circular",
        title: "Circular",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/circular",
      }, {
        id: "emp-details",
        title: "Emp Details",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/emp_details",
      }, {
        id: "birthdays",
        title: "Birthdays",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/birthdays",
      }, {
        id: "forget-password",
        title: "Forget Password",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/forget-password",
      }, {
        id: "change-password",
        title: "Change Password",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/change-password",
      }, {
        id: "messages",
        title: "Messages",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/messages",
      }, {
        id: "ip-change",
        title: "Ip Change",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/ip-change",
      }, {
        id: "attendance-list",
        title: "Attendance-List",
        translate: "",
        type: "item",
        icon: "disc",
        url: "hm/attendance-list",
      },
    ]
  },
  {
    id: "chqs",
    title: "CHQS",
    translate: "",
    type: "section",
    icon: "users",
    children: [
      {
        id: "uploads",
        title: "Uploads",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/uploads",
      }, {
        id: "not-uploads",
        title: "Not-Uploads",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/not-uploads",
      }, {
        id: "10-years",
        title: "10-Years",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/10-years",
      }, {
        id: "family-free",
        title: "Family Free",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/family-free",
      }, {
        id: "family-reffered",
        title: "Family Reffered",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/family-reffered",
      }, {
        id: "followup",
        title: "FollowUp",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/followup",
      }, {
        id: "bounce",
        title: "Bounce",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/bounce",
      }, {
        id: "cleared",
        title: "Cleared",
        translate: "",
        type: "item",
        icon: "disc",
        url: "chqs/cleared",
      },
    ]
  }, {
    id: "target",
    type: "section",
    title: "Target",
    translate: "",
    icon: "file",
    children: [
      {
        id: "transfer-report",
        type: "item",
        title: "Transfer-Report",
        translate: "",
        icon: "disc",
        url: "target/transfer-report",
      }, {
        id: "target-report",
        type: "item",
        title: "Target-Report",
        translate: "",
        icon: "disc",
        url: "target/target-report",
      }, {
        id: "target-op",
        type: "item",
        title: "Target-Op",
        translate: "",
        icon: "disc",
        url: "target/target-op",
      }, {
        id: "target-np",
        type: "item",
        title: "Target-Np",
        translate: "",
        icon: "disc",
        url: "target/target-np",
      }, {
        id: "higher-packages",
        type: "item",
        title: "Higher Packages",
        translate: "",
        icon: "disc",
        url: "target/higher-packages",
      }, 
      // {
      //   id: "daily-monthly-report",
      //   type: "item",
      //   title: "Daily & Monthly Report",
      //   translate: "",
      //   icon: "file",
      //   url: "target/daily-monthly-report",
      // }, 
      {
        id: "e-report",
        type: "item",
        title: "E-Report",
        translate: "",
        icon: "file",
        url: "target/e-report",
      }, {
        id: "doc-wise-report",
        type: "item",
        title: "Doc Wise Report",
        translate: "",
        icon: "disc",
        url: "target/doc-wise-report",
      }, {
        id: "month-report",
        type: "item",
        title: "Month Report",
        translate: "",
        icon: "disc",
        url: "target/month-report",
      }, {
        id: "status",
        type: "item",
        title: "Status",
        translate: "",
        icon: "disc",
        url: "target/status/np",
      }, {
        id: "sms-report",
        type: "item",
        title: "Sms Report",
        translate: "",
        icon: "disc",
        url: "target/sms-report",
      }
    ]
  },
  {
    id: "cc",
    type: "section",
    title: "CC",
    translate: "",
    icon: "file",
    children: [
      {
        id: "cc",
        type: "item",
        title: "CC",
        translate: "",
        icon: "disc",
        url: "target/status/call-center",
      }, {
        id: "ib",
        type: "item",
        title: "IB",
        translate: "",
        icon: "disc",
        url: "target/status/ib",
      }, {
        id: "ob",
        type: "item",
        title: "OB",
        translate: "",
        icon: "disc",
        url: "target/status/ob",
      }, {
        id: "cc_status",
        type: "item",
        title: "CC Status PH & Star",
        translate: "",
        icon: "disc",
        url: "cc/cc-status",
      },  {
        id: "cc_status",
        type: "item",
        title: "CC Status PH",
        translate: "",
        icon: "disc",
        url: "cc/cc-status-ph",
      },  {
        id: "cc_status",
        type: "item",
        title: "CC Status Star",
        translate: "",
        icon: "disc",
        url: "cc/cc-status-star",
      }, {
        id: "camp__status",
        type: "item",
        title: "CAMP STATUS",
        translate: "",
        icon: "disc",
        url: "cc/camp-status",
      }, {
        id: "marketing_status",
        type: "item",
        title: "Marketing Status",
        translate: "",
        icon: "disc",
        url: "cc/marketing-status",
      }, {
        id: "doctor_oncall_status",
        type: "item",
        title: "DOCTOR ONCALL STATUS",
        translate: "",
        icon: "disc",
        url: "cc/doctor-oncall-status",
      }, {
        id: "online_oncall_status",
        type: "item",
        title: "Online ONCALL STATUS",
        translate: "",
        icon: "disc",
        url: "cc/online-oncall-status",
      }, {
        id: "cc_oncall_status",
        type: "item",
        title: "CC ONCALL",
        translate: "",
        icon: "disc",
        url: "cc/cc-oncall-status",
      }, 
      {
        id: "spearon_status",
        type: "item",
        title: "SPEARON STATUS",
        translate: "",
        icon: "disc",
        url: "cc/spearon-status",
      },
      // {
      //   id: "ph_star_staus",
      //   type: "item",
      //   title: "PH->Star STATUS",
      //   translate: "",
      //   icon: "file",
      //   url: "cc/ph-star-status",
      // },
      // {
      //   id: "all_status",
      //   type: "item",
      //   title: "All STATUS",
      //   translate: "",
      //   icon: "file",
      //   url: "cc/all-status",
      // },
    ],
  },
  {
    id: "closing",
    type: "section",
    title: "Closing",
    translate: "",
    icon: "file",
    children: [
      {
        id: "br",
        type: "item",
        title: "BR",
        translate: "",
        icon: "disc",
        url: "closing/br",
      }, {
        id: "ho",
        type: "item",
        title: "HO",
        translate: "",
        icon: "disc",
        url: "closing/ho",
      }, {
        id: "kco",
        type: "item",
        title: "KCO",
        translate: "",
        icon: "disc",
        url: "closing/kco",
      }
    ],
  }, {
    id: "deposits",
    type: "section",
    title: "Deposits",
    translate: "",
    icon: "file",
    children: [
      {id: "un-confirm", type: "item", title: "Un-Confirm", translate: "", icon: "disc", url: "deposits/un-confirm"},
      {id: "confirm", type: "item", title: "Confirm", translate: "", icon: "disc", url: "deposits/confirm"}
    ]
  }, {
    id: "reports",
    title: "Reports",
    type: "section",
    icon: "file",
    children: [
      { id: "1", title: "Doctor Daily", type: "item", icon: "disc", url: "reports/doctor-daily" },
      { id: "2", title: "Refunds", type: "item", icon: "disc", url: "reports/refunds" },
      { id: "3", title: "HPKGS", type: "item", icon: "disc", url: "reports/hpkgs" },
      // { id: "4", title: "Doctor Target", type: "item", icon: "disc", url: "reports/doctor-target" },
      { id: "5", title: "Branch Wise", type: "item", icon: "disc", url: "reports/br/branch-wise" },
      { id: "6", title: "Region Wise", type: "item", icon: "disc", url: "reports/br/region-wise" },
      { id: "7", title: "Doctor Wise", type: "item", icon: "disc", url: "reports/br/doctor-wise" },
      { id: "8", title: "Particular Doctor Wise", type: "item", icon: "disc", url: "reports/br/per-doc-wise" },
    ],
  }, {
    id: "vouchers",
    title: "Vouchers",
    type: "section",
    icon: "file",
    children: [
      { id: "1", title: "Date Wise EXP(CO)", type: "item", icon: "disc", url: "vouchers/all-expenditure-co" },
      { id: "2", title: "Date Wise EXP(KCO)", type: "item", icon: "disc", url: "vouchers/expenditure-co-link" },
      { id: "3", title: "Payslip Attendance", type: "item", icon: "disc", url: "vouchers/pay-attendance" },
    ],
  },{
    id: "search",
    title: "Search",
    type: "section",
    icon: "search",
    children: [
      { id: "1", title: "Employee", type: "item", icon: "disc", url: "search/employees" },
      { id: "2", title: "Patients", type: "item", icon: "disc", url: "search/patients" },
    ],
  },
  {
    id: "target form",
    title: "Target Form",
    type: "item",
    icon: "box",
    url: 'target-form'
  },
  {
    id: "Edit Data",
    title: "Edit Data",
    type: "item",
    icon: "box",
    url: 'edit-data'
  },
  {
    id: "Patients",
    title: "Patients",
    type: "item",
    icon: "box",
    url: 'search/patient-list'
  }
];
