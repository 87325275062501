import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ProService } from '@pro/services/pro.service';

import { NotificationService } from '@core/services/notification.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-pay-due-billing',
  templateUrl: './pay-due-billing.component.html',
  styleUrls: ['./pay-due-billing.component.scss']
})
export class PayDueBillingComponent implements OnInit {

  private sub: any;
  patient_id: number;
  caseSheetData: any;
  patient_info: any;
  billingsData: any;
  backendValidations: any;
  public loading = new BehaviorSubject(false);
  date_of_expiry: any;

  paymentOptions = [
    { id: "c", value: "cash" },
    { id: "ch", value: "cheque" },
    { id: "cc", value: "credit card" },
    { id: "o", value: "ONLINE" },
    { id: "emi", value: "EMI" },
  ];

  packageStatus =[
    { id: 1, title:'Acq TO Register', value: "acq to reg" },
    { id: 2, title:'Regular To Register', value: "rl to reg" },
    { id: 3, title:'Acq To Regular', value: "acq to rl" },
    { id: 4, title:'Regular To Regular', value: "rl to rl" },
    { id: 5, title:'Regular To Acq', value: "rl to acq" },
    { id: 6, title:'Increase', value: "increase" },
    { id: 7, title:'Renewal', value: "Renewal" },
  ];

  public paymentType: boolean = false;
  public checkDetails: boolean = false;
  public creditCard: boolean = false;
  public online: boolean = false;

  /** Reset All Payment Type Validations */
  resetAllPayementTypeValidations() {
    this.patientBillingForm.controls.cheque_no.setValidators(null);
    this.patientBillingForm.controls.cheque_no.updateValueAndValidity();
    this.patientBillingForm.controls.bank_name.setValidators(null);
    this.patientBillingForm.controls.bank_name.updateValueAndValidity();
    this.patientBillingForm.controls.cheque_date.setValidators(null);
    this.patientBillingForm.controls.cheque_date.updateValueAndValidity();
    this.patientBillingForm.controls.invoice_no.setValidators(null);
    this.patientBillingForm.controls.invoice_no.updateValueAndValidity();
    this.patientBillingForm.controls.card_type.setValidators(null);
    this.patientBillingForm.controls.card_type.updateValueAndValidity();
  }

  selectPaymentType(id) {
    this.paymentType = true;
    this.resetAllPayementTypeValidations();
    id == "ch" ? (this.checkDetails = true) : (this.checkDetails = false);
    id == "cc" ? (this.creditCard = true) : (this.creditCard = false);
    id == "o" ? (this.online = true) : (this.online = false);

    if (id == "ch") {

      this.patientBillingForm.controls.cheque_no.setValidators(Validators.required);
      this.patientBillingForm.controls.bank_name.setValidators(Validators.required);
      this.patientBillingForm.controls.cheque_date.setValidators(Validators.required);
    } else if (id == "cc") {

      this.patientBillingForm.controls.invoice_no.setValidators(Validators.required);
      this.patientBillingForm.controls.card_type.setValidators(Validators.required);
    } else if (id == "o") {

      this.patientBillingForm.controls.ol_bank_name.setValidators(Validators.required);
      this.patientBillingForm.controls.account_number.setValidators(Validators.required);
    }
  }

  allowNumbersOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode != "0") {
      event.preventDefault();
    }
  }

  // patient billing form validation
  patientBillingForm: FormGroup = this._fb.group({
    no_of_days_medicine: ["", [Validators.required, Validators.pattern('^(?!.*  ).+')]],
    paymentOptionsEntry: ["", [Validators.required]],
    // total_amt: ["", [Validators.required, Validators.pattern('^(?!.*  ).+')]],
    due_amount: [null, Validators.required],
    discount: [null],
    received_amount: [null, Validators.required],
    increase_amount: [null],
    amount: [null],
    gst_amount: [null],
    plan: [null],
    package_status: [null],
    cheque_no: [""],
    bank_name: [""],
    cheque_date: [""],
    invoice_no: [""],
    card_type: [""],
    ol_bank_name: [""],
    account_number: [""]
  });

  get no_of_days_medicine() { return this.patientBillingForm.get('no_of_days_medicine') };
  get payment_Options() { return this.patientBillingForm.get('paymentOptionsEntry') };
  get totalAmount() { return this.patientBillingForm.get('total_amt') };
  get cheque_no() { return this.patientBillingForm.get('cheque_no') };
  get bank_name() { return this.patientBillingForm.get('bank_name') };
  get cheque_date() { return this.patientBillingForm.get('cheque_date') };
  get due_amount() { return this.patientBillingForm.get('due_amount'); }
  get invoice_no() { return this.patientBillingForm.get('invoice_no') };
  get card_type() { return this.patientBillingForm.get('card_type') };
  get ol_bank_name() { return this.patientBillingForm.get('ol_bank_name') };
  get account_number() { return this.patientBillingForm.get('account_number') };
  get received_amount() { return this.patientBillingForm.get('received_amount'); }
  get increase_amount() { return this.patientBillingForm.get('increase_amount'); }
  get amount() { return this.patientBillingForm.get('amount'); }
  get gst_amount() { return this.patientBillingForm.get('gst_amount'); }
  get plan() { return this.patientBillingForm.get('plan'); }
  get package_status() { return this.patientBillingForm.get('package_status'); }

  get discount() { return this.patientBillingForm.get('discount'); }

  errorMessages = {
    no_of_days_medicine: [{ type: 'required', message: 'The No of Days For Medicines Field is required' }],
    cheque_no: [{ type: 'required', message: 'This Field is required' }],
    bank_name: [{ type: 'required', message: 'This Field is required' }],
    cheque_date: [{ type: 'required', message: 'This Field is required' }],
    invoice_no: [{ type: 'required', message: 'This Field is required' }],
    card_type: [{ type: 'required', message: 'This Field is required' }],
    ol_bank_name: [{ type: 'required', message: 'This Field is required' }],
    account_number: [{ type: 'required', message: 'This Field is required' }],
    amount: [{ type: 'required', message: 'This Field is required' }],
    gst_amount: [{ type: 'required', message: 'This Field is required' }],
    received_amount: [{ type: 'required', message: 'This Field is required' },{ type: 'max', message: 'Invalid Received Amount' }],
    due_amount: [{ type: 'required', message: 'This Field is required' }],
    discount: [{ type: 'required', message: 'This Field is required' }],
    // package_status: [{ type: 'required', message: 'This Field is required' }],
  }

  submitPatientBillingForm() {
    this.patientBillingForm.markAllAsTouched();
    console.log(this.patientBillingForm.valid);
    if (this.patientBillingForm.valid) {
      this.patientBillingForm.value.casesheet_id = this.caseSheetData.id;
      this.patientBillingForm.value.doctor_id = this.caseSheetData.doctor_id;
      this.patientBillingForm.value.patient_type = this.caseSheetData.patient_type;
      this.patientBillingForm.value.treatment_duration = this.caseSheetData.no_of_days;

      this.patientBillingForm.value.patient_id = this.patient_id;

      this._service.duePayment(this.patientBillingForm.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.router.navigate(['pro/home/patient-receipt/' + this.patient_id]);
            this.loading.next(true);
          } else if (res['status'] == 'errors') {
            for (var key in res['errorValidations']) {
              let backendValidations = res["errorValidations"];
              this.backendValidations = backendValidations;
              this.patientBillingForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }
  // patient billing form validation ends

  constructor(private modalService: NgbModal,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    private _fb: FormBuilder,
    private _service: ProService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.patient_id = +params['id']
    });
    this.getCaseSheet();

    this.patientBillingForm.get('plan').valueChanges.subscribe(x => {
      if (x !== "") {
        this.date_of_expiry = this.addMonths(x);
      } else {
        this.date_of_expiry = '';
      }
    });
  }

  addMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() + numOfMonths);
    return date;
  }

  /* Get CaseSheet */
  getCaseSheet() {
    this._service.getCaseSheet(this.patient_id)
      .subscribe(res => {
        let data = res['data'];
        this.patient_info = data.patient_data;
        if (data.casesheet) {
          this.caseSheetData = data['casesheet'];
          this.billingsData = data['billings'];
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  modalOpen(modal) {
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
      backdrop: "static",
    });
  }

  // Calculate Due Amount
  calculateDueAmount() {
    let due_amount = this.billingsData[0]['due'];
    let received_amount = this.patientBillingForm.value.received_amount;
    let discount = this.patientBillingForm.value.discount;

    let current_due_amount = 0;
    if (due_amount == 0) {
      let increase_amount = this.patientBillingForm.value.increase_amount;
      current_due_amount = increase_amount - received_amount - discount;
    } else {
      current_due_amount = due_amount - received_amount - discount;
    }

    this.patientBillingForm.patchValue({
      'due_amount': current_due_amount
    });
    // this.patientBillingForm.get('received_amount').setValidators([Validators.required, Validators.max(current_due_amount)]);
  }

  // Calculate Due Amount
  calculateGstAmount() {
    let amount = this.patientBillingForm.value.amount;
    let gst = 0;
    if(this.patient_info.service_type == 'Skin Treatment' || this.patient_info.service_type == 'Hair Treatment'){
      gst = (parseInt(amount)*18) / (100 + 18);
    }

    let total_amount = parseInt(amount) + gst;
    this.patientBillingForm.patchValue({
      'increase_amount': total_amount,
      'gst_amount': gst.toFixed(2),
    });
    console.log(total_amount);
  }

  counter(i: number) {
    return new Array(i);
  }
}
