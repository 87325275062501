<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">Search For Patients</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="searchPatientForm" (ngSubmit)="searchPatients()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Type</label>
                                            <ng-select formControlName="search_field"
                                                [ngClass]="{ 'is-invalid error': errorMessages.search_field && search_field.errors && search_field.touched }"
                                                bindValue="value" bindLabel="title" [items]="searchType"
                                                placeholder="Select">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.search_field">
                                                <ng-container
                                                    *ngIf="search_field.hasError(error.type) && (search_field.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Patient Id</label>
                                            <input formControlName="keyword"
                                                [ngClass]="{ 'is-invalid error': errorMessages.keyword && keyword.errors && keyword.touched }"
                                                type="text" name="" id="" class="form-control">
                                            <div *ngFor="let error of errorMessages.keyword">
                                                <ng-container *ngIf="keyword.hasError(error.type) && (keyword.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                            <button (click)="resetFilter()" type="reset"
                                                class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title text-uppercase">{{ selectedType == 1 ? 'Last 10 Patient Billing Details' :
                        selectedType == 2 ? 'Last Consultation Details': '-'}} </h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="">
                        <table class="table table-bordered text-center align-middle" *ngIf="selectedType == 1">
                            <thead>
                                <tr>
                                    <th>Patient ID</th>
                                    <th>Patient Name</th>
                                    <th>Billing Date</th>
                                    <th>Package</th>
                                    <th>Total amount</th>
                                    <th>Recevied</th>
                                    <th>Due</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of patientsData">
                                    <td>{{ patientid }}</td>
                                    <td>{{ patient_name}}</td>
                                    <td>{{item.billing_date ? (item.billing_date | date: 'dd-MM-yyyy') : '-'}}</td>
                                    <td>{{ item.package_name}} M</td>
                                    <td>{{ item.total_amount}}</td>
                                    <td>{{ item.received_amount}}</td>
                                    <td>{{ item.due_amount}}</td>
                                    <td> <a class="badge badge-light-primary" ngbTooltip="Edit" placement="left"
                                        (click)="billingmodalOpen(editBillingDetails, item)" tooltipClass="fadeInAnimation">
                                        <span>
                                           Edit
                                        </span>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-bordered text-center align-middle" *ngIf="selectedType == 2">
                            <thead>
                                <tr>
                                    <th>Patient ID</th>
                                    <th>Patient Name</th>
                                    <th>Consult Date</th>
                                    <th>Consult Type</th>
                                    <th>consultation fee</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of patientsData">
                                    <td>{{ patientid }}</td>
                                    <td>{{ patient_name}}</td>
                                    <td>{{item.consult_date ? (item.consult_date | date: 'dd-MM-yyyy') : '-'}}</td>
                                    <td>{{item.consult_type == 'f' ? 'free': item.consult_type == 'c' ? 'Cost' : '-'}}</td>
                                    <td>{{ item.consultation_fee}}</td>
                                    <td> <a class="badge badge-light-primary" ngbTooltip="Edit" placement="left"
                                        (click)="consultmodalOpen(editConsultationDetails, item)" tooltipClass="fadeInAnimation">
                                        <span>
                                           Edit
                                        </span>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    
    <ng-template #editBillingDetails let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">PATIENT BILLING DETAILS EDIT</h4>
    
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <form [formGroup]="editPrescriptionForm" (ngSubmit)="updateBilling()">
            <!-- <form> -->
                <input type="hidden" name="patient_id" class="form-control" value="">
                <input type="hidden" name="patient_unique_id" class="form-control" value="">
                <input type="hidden" name="edit_for" class="form-control" value="">
                <input type="hidden" name="billing_id" class="form-control" value="">
                <input type="hidden" name="old_expiry_date" class="form-control" value="">
                <input type="hidden" name="old_package_name" class="form-control" value="">
                <input type="hidden" name="old_received_amt" class="form-control" value="">
                <input type="hidden" name="old_due_amt" class="form-control" value="">
                <input type="hidden" name="old_discount" class="form-control" value="">
                <input type="hidden" name="old_package_amt" class="form-control" value="">
                <input type="hidden" name="old_payment_option" class="form-control" value="">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                            <label for="">Package Name</label>
                            <input type="number" class="form-control" formControlName="new_package_name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                            <label for="">Package Amount </label>
                            <input type="number" class="form-control" formControlName="new_package_amt">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                            <label for="">Recevied Amount</label>
                            <input type="number" class="form-control" formControlName="new_received_amt">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                            <label for="">Due Amount</label>
                            <input type="number" class="form-control" formControlName="new_due_amt">
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                            <label for="">Discount</label>
                            <input type="number" class="form-control" formControlName="new_discount">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                            <label for="">Date Of Expiry</label>
                            <input type="date" class="form-control" formControlName="new_expiry_date">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="form-group">
                            <label for="">Payment Option</label>

                            <div class="form-group">
                                <div class="demo-inline-spacing">
                                    <div class="custom-control custom-radio mt-0"
                                        *ngFor="let p of paymentOptions">
                                        <input type="radio" id="{{p.name}}" value="{{p.id}}"
                                            name="new_payment_option" formControlName="new_payment_option"
                                            value="{{p.id}}" (change)="selectPaymentType(p.id)"
                                            class="custom-control-input" />
                                        <label class="custom-control-label"
                                            for="{{p.name}}">{{p.name}}</label>
                                    </div>
                                </div>
                                <div *ngIf="new_payment_option.invalid && new_payment_option.touched">
                                    <div class="text-danger" *ngIf="new_payment_option.errors?.required">Please
                                        select payment option.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          
                <div class="row mt-1" *ngIf="checkDetails">
                    <div class="col-12">
                        <div class="case-com-content pt-0 pb-50">
                            <h4>Cheque Details</h4>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Cheque No</label>
                                        <input formControlName="cheque_no" type="text" class="form-control" >
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Bank Name</label>
                                        <input formControlName="bank_name" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Cheque Date</label>
                                        <input formControlName="cheque_date" type="date"
                                            class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Cheque details -->

                <!-- Credit card -->
                <div class="row mt-1" *ngIf="creditCard">
                    <div class="col-12">
                        <div class="case-com-content pt-0 pb-50">
                            <h4>Credit Card</h4>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Invoice No</label>
                                        <input formControlName="invoice_no" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Card Type</label>
                                        <input formControlName="card_type" type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Credit card -->

                <!-- Online -->
                <div class="row mt-1" *ngIf="online">
                    <div class="col-12">
                        <div class="case-com-content pt-0 pb-50">
                            <h4>Online Details</h4>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Bank Name</label>
                                        <input type="text" class="form-control"
                                            formControlName="ol_bank_name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Account Number</label>
                                        <input type="text" class="form-control"
                                            (keypress)="allowNumbersOnly($event)"
                                            formControlName="account_number">
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="form-group">
                            <label for="">Remarks</label>
                            <textarea rows="3" class="form-control" formControlName="remarks">
                        </textarea>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-primary">Update</button>
                </div>
            </form>
        </div>
    </ng-template>
    <ng-template #editConsultationDetails let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">CONSULTATION EDIT</h4>
    
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <form [formGroup]="editConsultationForm" (ngSubmit)="updateConsultation()">
            <!-- <form> -->
                <input type="hidden" name="consult_patient_id" class="form-control" value="">
                <input type="hidden" name="consult_patient_unique_id" class="form-control" value="">
                <input type="hidden" name="consult_edit_for" class="form-control" value="">
                <input type="hidden" name="consult_id" class="form-control" value="">
                <input type="hidden" name="old_consult_fee" class="form-control" value="">
                <input type="hidden" name="old_cc_payment_option" class="form-control" value="">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="form-group">
                            <label for="">Consultation Fee</label>
                            <input type="text" class="form-control" formControlName="new_consult_fee">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="form-group">
                            <label for="">Payment Option</label>

                            <div class="form-group">
                                <div class="demo-inline-spacing">
                                    <div class="custom-control custom-radio mt-0"
                                        *ngFor="let p of paymentOptions">
                                        <input type="radio" id="{{p.name}}" value="{{p.id}}"
                                            name="new_cc_payment_option" formControlName="new_cc_payment_option"
                                            value="{{p.id}}" (change)="selectPaymentType(p.id)"
                                            class="custom-control-input" />
                                        <label class="custom-control-label"
                                            for="{{p.name}}">{{p.name}}</label>
                                    </div>
                                </div>
                                <div *ngIf="new_cc_payment_option.invalid && new_cc_payment_option.touched">
                                    <div class="text-danger" *ngIf="new_cc_payment_option.errors?.required">Please
                                        select payment option.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          
                <div class="row mt-1" *ngIf="checkDetails">
                    <div class="col-12">
                        <div class="case-com-content pt-0 pb-50">
                            <h4>Cheque Details</h4>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Cheque No</label>
                                        <input formControlName="cc_cheque_no" type="text" class="form-control" >
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Bank Name</label>
                                        <input formControlName="cc_bank_name" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Cheque Date</label>
                                        <input formControlName="cc_cheque_date" type="date"
                                            class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Cheque details -->

                <!-- Credit card -->
                <div class="row mt-1" *ngIf="creditCard">
                    <div class="col-12">
                        <div class="case-com-content pt-0 pb-50">
                            <h4>Credit Card</h4>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Invoice No</label>
                                        <input formControlName="cc_invoice_no" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Card Type</label>
                                        <input formControlName="cc_card_type" type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Credit card -->

                <!-- Online -->
                <div class="row mt-1" *ngIf="online">
                    <div class="col-12">
                        <div class="case-com-content pt-0 pb-50">
                            <h4>Online Details</h4>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Bank Name</label>
                                        <input type="text" class="form-control"
                                            formControlName="cc_ol_bank_name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Account Number</label>
                                        <input type="text" class="form-control"
                                            (keypress)="allowNumbersOnly($event)"
                                            formControlName="cc_account_number">
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="form-group">
                            <label for="">Remarks</label>
                            <textarea rows="3" class="form-control" formControlName="consult_remarks">
                        </textarea>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-primary">Update</button>
                </div>
            </form>
        </div>
    </ng-template>