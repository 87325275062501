<ng-template #consultationModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Consultation Receipt</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus id="consultationPrint">
        <center>
            <div style="border:solid 2px #0099FF;width:1000px">

                <p style="font-size:12px;font-weight:bold;font-family:arial;width:840px;text-align:left;line-height:200%"
                    align="center">
                </p>
                <p align="center">
                    <img src="https://hms.provitalehealth.com/assets/images/logo/positivehomeopathy_logo.jpg" border="0" width="40%">
                </p>
                <p></p>
                <table width="950" style="font-family:arial;font-size:12px;font-weight:bold;" align="center"
                    bgcolor="white">

                    <tbody>
                        <tr>
                            <td colspan="3" style=" font-weight:700;font-size:13px;text-align:center;">
                                <div [innerHtml]="currentUser.getBranchAddress()"></div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td width="41%"><span style="color:#0099FF">No. :</span>{{ selectedConsultation.receipt_no
                                }}</td>
                            <td width="31%" align="left" style="font-size:15px;color:#FF9900"><b> PATIENT RECEIPT</b>
                            </td>
                            <td width="28%" colspan="3"><span style="color:#0099FF">Date :</span> {{selectedConsultation.consult_date ? (selectedConsultation.consult_date | date:
                                'dd-MM-yyyy') : '-'}} </td>
                        </tr>


                        <tr>
                            <td><span style="color:#0099FF">Patient Name :</span> {{selectedConsultation.patient_name}}
                            </td>
                            <td><span style="color:#0099FF">Age :</span> {{selectedConsultation.age}} </td>
                            <td><span style="color:#0099FF">Sex :</span> {{ selectedConsultation.gender == 'm' ? 'Male'
                                : selectedConsultation.gender == 'm' ? 'Female'
                                : selectedConsultation.gender == 'o' ? 'Others' : '-'}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Regn. No. :</span>
                                {{selectedConsultation.patient_unique_id}}</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Received an amount of Rs. :</span>
                                {{selectedConsultation.consultation_fee ? selectedConsultation.consultation_fee :
                                'Free'}}
                                /-&nbsp;&nbsp;&nbsp;</td>
                        </tr>

                        <tr>
                            <td colspan="3"><span style="color:#0099FF"></span></td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Payment by :</span>
                                {{ selectedConsultation.payment_option == "c" ? "cash" :
                                selectedConsultation.payment_option == "cc" ? "Credit-Card" :
                                selectedConsultation.payment_option == "ch" ? "Cheque" :
                                selectedConsultation.payment_option == "o" ? "Online" : 'N/A'
                                }}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Fee :</span> <b> Consultation</b>
                            </td>
                            <td align="right"><span style="color:#0099FF">
                                </span></td>
                            <td><span style="color:#0099FF">EmpCode :</span> {{selectedEmployeeId}}</td>
                        </tr>

                        <tr>
                            <td colspan="4" align="center"><u><strong>TERMS &amp; CONDITIONS</strong></u>
                                <p style="font-weight:300;font-size:12px;text-align:justify;">*The facilities of joining
                                    the card includes any number of consultations with physician,medicines,courier
                                    (within india) during the duration of that particular card specified.<br>*Only the
                                    bearer can avail the facilities of the card.<br>*The card facilities are given only
                                    to the one on whose name the card is made.<br>*The fee is non transferable, non
                                    -refundable and non-extendable.<br>*Patients are strictly advised to use medicines
                                    as per attending physicians recommendation. We assume patients have the
                                    responsibility to inform the attending physician about the status of the health or
                                    any serious disorder during the course of treatment.<br>

                                    *We expect &amp; would appreciate patients to visit the clinic as per the due date
                                    of their consultations.<br>*Patients are requested to co-operate with the mode of
                                    treatment,as sometimes,the speed of recovery is slow (the time of recovery may
                                    vary).<br>*The duration of treatment and results may vary from patient.

                                    *The Doctor and the clinic has given no guarantee to me about the results and
                                    duration of the treatment.<br>*During critical emergencies patients / attendants are
                                    adviced to inform the attending physician.<br>

                                    *Case record are kept with the Doctor till the course of th treatment.<br> *All
                                    disputes are subject to Hyderabad only.E&amp;OE.</p>
                            </td>

                        </tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr style="font-weight:700;font-size:13px;text-align:center;">
                            <td>Patient / Attendants Signature</td>
                            <td>Branch Head Signature</td>
                            <td colspan="2" align="right">Authorized Signatory</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" style="font-size:15px;font-weight:700;text-align:center"></td>
                        </tr>

                        <tr>
                            <td colspan="6">
                                <hr>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </center>
        <br>
        <hr>
        <br>
        <center>
            <div style="border:solid 2px #0099FF;width:1000px">

                <p style="font-size:12px;font-weight:bold;font-family:arial;width:840px;text-align:left;line-height:200%"
                    align="center">
                </p>
                <p align="center"><img src="https://hms.provitalehealth.com/assets/images/logo/positivehomeopathy_logo.jpg" border="0" width="40%"></p>
                <p></p>
                <table width="950" style="font-family:arial;font-size:12px;font-weight:bold;" align="center"
                    bgcolor="white">

                    <tbody>
                        <tr>
                            <td colspan="3" style=" font-weight:700;font-size:13px;text-align:center;">
                                <table width="100%" style="font-size:13px;font-family:arial">
                                    <div [innerHtml]="currentUser.getBranchAddress()"></div>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td width="41%"><span style="color:#0099FF">No. :</span>{{ selectedConsultation.receipt_no
                                }}</td>
                            <td width="31%" align="left" style="font-size:15px;color:#FF9900"><b> PATIENT RECEIPT</b>
                            </td>
                            <td width="28%" colspan="3"><span style="color:#0099FF">Date :</span> {{selectedConsultation.consult_date ? (selectedConsultation.consult_date | date:
                                'dd-MM-yyyy') : '-'}} </td>
                        </tr>


                        <tr>
                            <td><span style="color:#0099FF">Patient Name :</span> {{selectedConsultation.patient_name}}
                            </td>
                            <td><span style="color:#0099FF">Age :</span> {{selectedConsultation.age}} </td>
                            <td><span style="color:#0099FF">Sex :</span> {{ selectedConsultation.gender == 'm' ? 'Male'
                                : selectedConsultation.gender == 'm' ? 'Female'
                                : selectedConsultation.gender == 'o' ? 'Others' : '-'}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Regn. No. :</span>
                                {{selectedConsultation.patient_unique_id}}</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Received an amount of Rs. :</span>
                                {{selectedConsultation.consultation_fee ? selectedConsultation.consultation_fee :
                                'Free'}}
                                /-&nbsp;&nbsp;&nbsp;</td>
                        </tr>

                        <tr>
                            <td colspan="3"><span style="color:#0099FF"></span></td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Payment by :</span>
                                {{ selectedConsultation.payment_option == "c" ? "cash" :
                                selectedConsultation.payment_option == "cc" ? "Credit-Card" :
                                selectedConsultation.payment_option == "ch" ? "Cheque" :
                                selectedConsultation.payment_option == "o" ? "Online" : 'N/A'
                                }}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Fee :</span> <b>Consultation</b>
                            </td>
                            <td align="right"><span style="color:#0099FF">
                                </span></td>
                            <td><span style="color:#0099FF">EmpCode :</span> {{selectedEmployeeId}}</td>
                        </tr>

                        <tr>
                            <td colspan="4" align="center"><u><strong>TERMS &amp; CONDITIONS</strong></u>
                                <p style="font-weight:300;font-size:12px;text-align:justify;">*The facilities of joining
                                    the card includes any number of consultations with physician,medicines,courier
                                    (within india) during the duration of that particular card specified.<br>*Only the
                                    bearer can avail the facilities of the card.<br>*The card facilities are given only
                                    to the one on whose name the card is made.<br>*The fee is non transferable, non
                                    -refundable and non-extendable.<br>*Patients are strictly advised to use medicines
                                    as per attending physicians recommendation. We assume patients have the
                                    responsibility to inform the attending physician about the status of the health or
                                    any serious disorder during the course of treatment.<br>

                                    *We expect &amp; would appreciate patients to visit the clinic as per the due date
                                    of their consultations.<br>*Patients are requested to co-operate with the mode of
                                    treatment,as sometimes,the speed of recovery is slow (the time of recovery may
                                    vary).<br>*The duration of treatment and results may vary from patient.

                                    *The Doctor and the clinic has given no guarantee to me about the results and
                                    duration of the treatment.<br>*During critical emergencies patients / attendants are
                                    adviced to inform the attending physician.<br>

                                    *Case record are kept with the Doctor till the course of th treatment.<br> *All
                                    disputes are subject to Hyderabad only.E&amp;OE.</p>
                            </td>

                        </tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr style="font-weight:700;font-size:13px;text-align:center;">
                            <td>Patient / Attendants Signature</td>
                            <td>Branch Head Signature</td>
                            <td colspan="2" align="right">Authorized Signatory</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" style="font-size:15px;font-weight:700;text-align:center"></td>
                        </tr>

                        <tr>
                            <td colspan="6">
                                <hr>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </center>
        <div class="text-center mt-50">
            <button (click)="print()" class="btn btn-primary">Print</button>
        </div>
    </div>
</ng-template>