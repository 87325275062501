import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CurrentUserService } from '@services/current-user/current-user.service';
import { ProService } from '@pro/services/pro.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-update-billing',
  templateUrl: './update-billing.component.html',
  styleUrls: ['./update-billing.component.scss']
})
export class UpdateBillingComponent implements OnInit {

  private sub: any;
  patient_id: number;
  caseSheetData: any;
  patient_info: any;
  billingsData: any;
  public show_package_section: any = false;
  backendValidations: any;
  public loading = new BehaviorSubject(false);

  public paymentType: boolean = false;
  public checkDetails: boolean = false;
  public creditCard: boolean = false;
  public online: boolean = false;
  public plan_section: any = false;
  date_of_expiry: any;

  plans = [
    { id: 1, value: "1 Month" },
    { id: 2, value: "2 Months" },
    { id: 3, value: "3 Months" },
    { id: 4, value: "4 Months" },
    { id: 5, value: "5 Months" },
  ];

  paymentOptions = [
    { id: "c", value: "cash" },
    { id: "ch", value: "cheque" },
    { id: "cc", value: "credit card" },
    { id: "o", value: "ONLINE" },
    { id: "emi", value: "EMI" },
  ];

  patientBillingForm: FormGroup = this.fb.group({
    no_of_days_medicine: [null, [Validators.required, Validators.pattern('^(?!.*  ).+')]],
    plan: [null, Validators.required],
    // branch_patient_id: [null, Validators.required],
    payment_option: [null, Validators.required],
    total_amount: [null, Validators.required],
    amount: [null, Validators.required],
    received_amount: [null, Validators.required],
    due_amount: [null, Validators.required],
    cheque_no: [null],
    bank_name: [null],
    cheque_date: [null],
    invoice_no: [null],
    card_type: [null],
    ol_bank_name: [null],
    account_number: [null],
    gst_amount: [null]
  });

  get no_of_days_medicine() { return this.patientBillingForm.get('no_of_days_medicine'); }
  get plan() { return this.patientBillingForm.get('plan'); }
  // get branch_patient_id() { return this.patientBillingForm.get('branch_patient_id'); }
  get payment_option() { return this.patientBillingForm.get('payment_option'); }
  get total_amount() { return this.patientBillingForm.get('total_amount'); }
  get amount() { return this.patientBillingForm.get('amount'); }
  get received_amount() { return this.patientBillingForm.get('received_amount'); }
  get due_amount() { return this.patientBillingForm.get('due_amount'); }
  get cheque_no() { return this.patientBillingForm.get('cheque_no'); }
  get bank_name() { return this.patientBillingForm.get('bank_name'); }
  get cheque_date() { return this.patientBillingForm.get('cheque_date'); }
  get invoice_no() { return this.patientBillingForm.get('invoice_no'); }
  get card_type() { return this.patientBillingForm.get('card_type'); }
  get ol_bank_name() { return this.patientBillingForm.get('ol_bank_name'); }
  get account_number() { return this.patientBillingForm.get('account_number'); }
  get gst_amount() { return this.patientBillingForm.get('gst_amount'); }

  errorMessages = {
    no_of_days_medicine: [{ type: 'required', message: 'The No of Dayes For Medicines Field is required' }],
    plan: [{ type: 'required', message: 'The Treatment Plan Field is required' }],
    // branch_patient_id: [{ type: 'required', message: 'The Branch Patient Id Field is required' }],
    payment_option: [{ type: 'required', message: 'The Payment Option Field is required' }],
    total_amount: [{ type: 'required', message: 'The Total Amount Field is required' }],
    received_amount: [{ type: 'required', message: 'The Received Amount Field is required' },
    { type: 'max', message: 'Invalid Received Amount' }],
    due_amount: [{ type: 'required', message: 'The Due Amount Field is required' }],
    cheque_no: [{ type: 'required', message: 'The Cheque No Field is required' }],
    bank_name: [{ type: 'required', message: 'The Bank Name Field is required' }],
    cheque_date: [{ type: 'required', message: 'The Cheque Date Field is required' }],
    invoice_no: [{ type: 'required', message: 'The Invoice No Field is required' }],
    card_type: [{ type: 'required', message: 'The Card Type Field is required' }],
    ol_bank_name: [{ type: 'required', message: 'The Online Bank Name Field is required' }],
    account_number: [{ type: 'required', message: 'The Banck Account Number Field is required' }]
  }

  /** Reset All Payment Type Validations */
  resetAllPayementTypeValidations() {
    this.patientBillingForm.controls.cheque_no.setValidators(null);
    this.patientBillingForm.controls.cheque_no.updateValueAndValidity();
    this.patientBillingForm.controls.bank_name.setValidators(null);
    this.patientBillingForm.controls.bank_name.updateValueAndValidity();
    this.patientBillingForm.controls.cheque_date.setValidators(null);
    this.patientBillingForm.controls.cheque_date.updateValueAndValidity();
    this.patientBillingForm.controls.invoice_no.setValidators(null);
    this.patientBillingForm.controls.invoice_no.updateValueAndValidity();
    this.patientBillingForm.controls.card_type.setValidators(null);
    this.patientBillingForm.controls.card_type.updateValueAndValidity();
  }

  selectPaymentType(id) {
    this.paymentType = true;
    this.resetAllPayementTypeValidations();
    id == "ch" ? (this.checkDetails = true) : (this.checkDetails = false);
    id == "cc" ? (this.creditCard = true) : (this.creditCard = false);
    id == "o" ? (this.online = true) : (this.online = false);

    if (id == "ch") {

      this.patientBillingForm.controls.cheque_no.setValidators(Validators.required);
      this.patientBillingForm.controls.bank_name.setValidators(Validators.required);
      this.patientBillingForm.controls.cheque_date.setValidators(Validators.required);
    } else if (id == "cc") {

      this.patientBillingForm.controls.invoice_no.setValidators(Validators.required);
      this.patientBillingForm.controls.card_type.setValidators(Validators.required);
    } else if (id == "o") {

      this.patientBillingForm.controls.ol_bank_name.setValidators(Validators.required);
      this.patientBillingForm.controls.account_number.setValidators(Validators.required);
    }
  }

  constructor(private fb: FormBuilder,
    private _service: ProService,
    private router: Router,
    private notifyService: NotificationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.patientBillingForm.get('plan').valueChanges.subscribe(x => {
      if (x !== "") {
        this.date_of_expiry = this.addMonths(x);
        this.plan_section = true;
      } else {
        this.plan_section = false;
        this.date_of_expiry = '';
      }
    });

    this.sub = this.route.params.subscribe(params => {
      this.patient_id = +params['id']
    });
    this.getCaseSheet();
  }

  addMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() + numOfMonths);

    return date;
  }

  allowNumbersOnly(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode != "0") {
      event.preventDefault();
    }
  }

  /* Get CaseSheet */
  getCaseSheet() {
    this._service.getCaseSheet(this.patient_id)
      .subscribe(res => {
        let data = res['data'];
        this.patient_info = data.patient_data;
        if (data.casesheet) {
          this.caseSheetData = data['casesheet'];
          this.billingsData = data['billings'];
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  showPackageContent() {
    this.show_package_section = true;
  }

  /* Update Billing */
  updateBilling() {
    this.patientBillingForm.markAllAsTouched();

    if (this.patientBillingForm.valid) {
      this.patientBillingForm.value.doctor_id = this.caseSheetData.doctor_id;
      this.patientBillingForm.value.patient_type = this.caseSheetData.patient_type;
      this.patientBillingForm.value.treatment_duration = this.caseSheetData.no_of_days;

      this.patientBillingForm.value.patient_id = this.patient_id;
      this.loading.next(true);
      this._service.updateBilling(this.patientBillingForm.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.router.navigate(['pro/home/patient-receipt/' + this.patient_id]);
            // this.router.navigate(['pro/home/new-patients-billing']);
          } else if (res['status'] == 'errors') {
            for (var key in res['errorValidations']) {
              let backendValidations = res["errorValidations"];
              this.backendValidations = backendValidations;
              this.patientBillingForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  counter(i: number) {
    return new Array(i);
  }

  // Calculate Due Amount
  calculateDueAmount() {
    let total_amount = this.patientBillingForm.value.total_amount;
    let received_amount = this.patientBillingForm.value.received_amount;

    let due_amount = total_amount - received_amount;
    this.patientBillingForm.patchValue({
      'due_amount': due_amount
    });
    console.log(total_amount);
    this.patientBillingForm.get('received_amount').setValidators([Validators.required, Validators.max(total_amount)]);
  }
  
  // Calculate Due Amount
  calculateGstAmount() {
    let amount = this.patientBillingForm.value.amount;
    let gst = 0;
    if(this.patient_info.service_type == 'Skin Treatment' || this.patient_info.service_type == 'Hair Treatment'){
      gst = (parseInt(amount)*18) / (100 + 18);
    }

    let total_amount = amount;
    this.patientBillingForm.patchValue({
      'total_amount': amount,
      'gst_amount': gst.toFixed(2),
    });
    console.log(total_amount);
  }
}
