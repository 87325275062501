import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import * as snippet from "app/main/tables/datatables/datatables.snippetcode";
import { Subject, BehaviorSubject } from "rxjs";
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterDataService } from '@services/masterData/master-data.service';

import { AccountsService } from '@accounts/services/accounts.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';
import { GmService } from "@gm/services/gm.service";
import { CurrentUserService } from "@services/current-user/current-user.service";

@Component({
  selector: 'app-edit-data',
  templateUrl: './edit-data.component.html',
  styleUrls: ['./edit-data.component.scss']
})
export class EditDataComponent implements OnInit {

  public mySearchControl = new FormControl();
  isShown: boolean = false;
  public filterLoading = new BehaviorSubject(false);
  public basicSelectedOption: number = 500;
  selectedPatient: any;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  search_text: any;
  selectedRow: any;
  searchPatientForm = this._formBuilder.group({
    search_field: [null, Validators.required],
    keyword: [null, Validators.required],
    perpage: this.basicSelectedOption,
    page: 1
  });

  editPrescriptionForm = this._formBuilder.group({
    patient_id: [""],
    patient_unique_id: [""],
    edit_for: [""],
    billing_id: [""],
    old_expiry_date: [""],
    old_package_name: [""],
    old_received_amt: [""],
    old_due_amt: [""],
    old_discount: [""],
    old_package_amt: [""],
    old_payment_option: [""],
    new_package_name: [null, Validators.required],
    new_package_amt: [null, Validators.required],
    new_received_amt: [null, Validators.required],
    new_due_amt: [null, Validators.required],
    new_discount: [null, Validators.required],
    new_payment_option: [null, Validators.required],
    new_expiry_date: [null, Validators.required],
    cheque_no: [""],
    bank_name: [""],
    cheque_date: [""],
    invoice_no: [""],
    card_type: [""],
    ol_bank_name: [""],
    account_number: [""],
    remarks: [
      "",
      [
        Validators.required,
        Validators.pattern("\\s?\\S+(?: \\S+)*\\s?"),
        Validators.maxLength(250),
      ],
    ],

  });
  editConsultationForm = this._formBuilder.group({
    consult_patient_id: [""],
    consult_patient_unique_id: [""],
    consult_edit_for: [""],
    consult_id: [""],
    old_cc_payment_option: [""],
    new_cc_payment_option: [""],
    cc_cheque_no: [""],
    cc_bank_name: [""],
    cc_cheque_date: [""],
    cc_invoice_no: [""],
    cc_card_type: [""],
    cc_ol_bank_name: [""],
    cc_account_number: [""],
    old_consult_fee: [""],
    new_consult_fee: [null, Validators.required],
    consult_remarks: [
      "",
      [
        Validators.required,
        Validators.pattern("\\s?\\S+(?: \\S+)*\\s?"),
        Validators.maxLength(250),
      ],
    ],

  });
  selectedRecord:any;


  get search_field() { return this.searchPatientForm.get('search_field'); }
  get keyword() { return this.searchPatientForm.get('keyword'); }
//Billing form Get Value
  get patient_id() { return this.editPrescriptionForm.get('patient_id'); }
  get patient_unique_id() { return this.editPrescriptionForm.get('patient_unique_id'); }
  get edit_for() { return this.editPrescriptionForm.get('edit_for'); }
  get old_expiry_date() { return this.editPrescriptionForm.get('old_expiry_date'); }
  get old_package_name() { return this.editPrescriptionForm.get('old_package_name'); }
  get old_received_amt() { return this.editPrescriptionForm.get('old_received_amt'); }
  get old_due_amt() { return this.editPrescriptionForm.get('old_due_amt'); }
  get old_discount() { return this.editPrescriptionForm.get('old_discount'); }
  get old_package_amt() { return this.editPrescriptionForm.get('old_package_amt'); }
  get old_payment_option() { return this.editPrescriptionForm.get('old_payment_option'); }
  get new_package_name() { return this.editPrescriptionForm.get('new_package_name'); }
  get new_package_amt() { return this.editPrescriptionForm.get('new_package_amt'); }
  get new_received_amt() { return this.editPrescriptionForm.get('new_received_amt'); }
  get new_due_amt() { return this.editPrescriptionForm.get('new_due_amt'); }
  get new_discount() { return this.editPrescriptionForm.get('new_discount'); }
  get new_payment_option() { return this.editPrescriptionForm.get('new_payment_option'); }
  get new_expiry_date() { return this.editPrescriptionForm.get('new_expiry_date'); }
  get remarks() { return this.editPrescriptionForm.get('remarks'); }
  get cheque_no() { return this.editPrescriptionForm.get('cheque_no'); }
  get bank_name() { return this.editPrescriptionForm.get('bank_name'); }
  get cheque_date() { return this.editPrescriptionForm.get('cheque_date'); }
  get invoice_no() { return this.editPrescriptionForm.get('invoice_no'); }
  get card_type() { return this.editPrescriptionForm.get('card_type'); }
  get ol_bank_name() { return this.editPrescriptionForm.get('ol_bank_name'); }
  get account_number() { return this.editPrescriptionForm.get('account_number'); }
// Consult form get Value
  get consult_patient_id() { return this.editConsultationForm.get('consult_patient_id'); }
  get consult_patient_unique_id() { return this.editConsultationForm.get('consult_patient_unique_id'); }
  get consult_edit_for() { return this.editConsultationForm.get('consult_edit_for'); }
  get consult_id() { return this.editConsultationForm.get('consult_id'); }
  get old_consult_fee() { return this.editConsultationForm.get('old_consult_fee'); }
  get new_consult_fee() { return this.editConsultationForm.get('new_consult_fee'); }
  get old_cc_payment_option() { return this.editConsultationForm.get('old_cc_payment_option'); }
  get new_cc_payment_option() { return this.editConsultationForm.get('new_cc_payment_option'); }
  get consult_remarks() { return this.editConsultationForm.get('consult_remarks'); }
  get cc_cheque_no() { return this.editConsultationForm.get('cheque_no'); }
  get cc_bank_name() { return this.editConsultationForm.get('bank_name'); }
  get cc_cheque_date() { return this.editConsultationForm.get('cheque_date'); }
  get cc_invoice_no() { return this.editConsultationForm.get('invoice_no'); }
  get cc_card_type() { return this.editConsultationForm.get('card_type'); }
  get cc_ol_bank_name() { return this.editConsultationForm.get('ol_bank_name'); }
  get cc_account_number() { return this.editConsultationForm.get('account_number'); }
 
  errorMessages = {
    search_field: [{ type: 'required', message: 'This field is required' }],
    keyword: [{ type: 'required', message: 'This field is required' }],
  }

  public searchType = [
    { title: 'Patient Billings', value: '1' },
    { title: 'Consultations', value: '2' }
  ];

  public paymentOptions = [
    {id: 'c', name: 'Cash'},
    {id: 'ch', name: 'Cheque'},
    {id: 'cc', name: 'Credit Card'},
    {id: 'o', name: 'online'}
  ];
// Private
private _unsubscribeAll: Subject<any>;
private tempData = [];

// public
public rows: any;
public selected = [];
public kitchenSinkRows: any;
public ColumnMode = ColumnMode;
public expanded = {};
public chkBoxSelected = [];
public SelectionType = SelectionType;
public modalRef: any;

public patientsData: any[] = [];
public selectedType: any;
public patientid: any;
public patient_name: any;

public checkDetails: boolean = false;
public creditCard: boolean = false;
public online: boolean = false;
resetAllPayementTypeValidations() {
  this.editPrescriptionForm.controls.cheque_no.setValidators(null);
  this.editPrescriptionForm.controls.cheque_no.updateValueAndValidity();
  this.editPrescriptionForm.controls.bank_name.setValidators(null);
  this.editPrescriptionForm.controls.bank_name.updateValueAndValidity();
  this.editPrescriptionForm.controls.cheque_date.setValidators(null);
  this.editPrescriptionForm.controls.cheque_date.updateValueAndValidity();
  this.editPrescriptionForm.controls.invoice_no.setValidators(null);
  this.editPrescriptionForm.controls.invoice_no.updateValueAndValidity();
  this.editPrescriptionForm.controls.card_type.setValidators(null);
  this.editPrescriptionForm.controls.card_type.updateValueAndValidity();
}

selectPaymentType(id) {
  this.resetAllPayementTypeValidations();
  id == "ch" ? (this.checkDetails = true) : (this.checkDetails = false);
  id == "cc" ? (this.creditCard = true) : (this.creditCard = false);
  id == "o" ? (this.online = true) : (this.online = false);

  if (id == "ch") {

    this.editPrescriptionForm.controls.cheque_no.setValidators(Validators.required);
    this.editPrescriptionForm.controls.bank_name.setValidators(Validators.required);
    this.editPrescriptionForm.controls.cheque_date.setValidators(Validators.required);
  } else if (id == "cc") {

    this.editPrescriptionForm.controls.invoice_no.setValidators(Validators.required);
    this.editPrescriptionForm.controls.card_type.setValidators(Validators.required);
  } else if (id == "o") {

    this.editPrescriptionForm.controls.ol_bank_name.setValidators(Validators.required);
    this.editPrescriptionForm.controls.account_number.setValidators(Validators.required);
  }
}
@ViewChild(DatatableComponent) table: DatatableComponent;
@ViewChild("tableRowDetails") tableRowDetails: any;

// snippet code variables
public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

/**
 * Constructor
 */
constructor(
  private _service: GmService,
  private _formBuilder: FormBuilder,
  private notifyService: NotificationService,
  private masterService: MasterDataService,
  private _router: Router,
  public currentUser: CurrentUserService,
  private modalService: NgbModal) {
  this._unsubscribeAll = new Subject();
}


  ngOnInit(): void {
  }
  searchPatients() {
    this.searchPatientForm.markAllAsTouched();
    if (this.searchPatientForm.valid) {
      this.filterLoading.next(true);
      this.searchPatientForm.value.perpage = this.basicSelectedOption;
      this._service.searchBillings(this.searchPatientForm.value)
        .pipe(finalize(() => this.filterLoading.next(false)))
        .subscribe(res => {
          if(res['status'] == 'success'){
          let data = res['data'];
          this.patientsData = data['billingSearch'];
          this.patient_name = data['patient_name'];
          this.selectedType = this.searchPatientForm.value.search_field;
          this.patientid = this.searchPatientForm.value.keyword
          }
          if(res['status'] == 'errors'){
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  billingmodalOpen(modal, row = null) {
    if (row) {
      this.selectedRecord = row;
      this.patchBillingDetails();
    }

    this.modalRef = this.modalService.open(modal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true
    });
  }
  consultmodalOpen(modal, row = null) {
    if (row) {
      this.selectedRecord = row;
      this.patchConsultation();
    }

    this.modalRef = this.modalService.open(modal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true
    });
  }
  patchBillingDetails() {
    this.editPrescriptionForm.patchValue({
      'patient_id': this.selectedRecord.patient_id,
      'patient_unique_id': this.patientid,
      'edit_for': this.selectedType,
      'billing_id': this.selectedRecord.id,
      'old_expiry_date': this.selectedRecord.date_of_expire,
      'old_package_name': this.selectedRecord.package_name,
      'old_received_amt': this.selectedRecord.received_amount,
      'old_due_amt': this.selectedRecord.due_amount,
      'old_discount': this.selectedRecord.discount,
      'old_package_amt': this.selectedRecord.total_amount,
      'old_payment_option': this.selectedRecord.payment_option,
      'new_package_name': this.selectedRecord.package_name,
      'new_package_amt': this.selectedRecord.total_amount,
      'new_received_amt': this.selectedRecord.received_amount,
      'new_due_amt': this.selectedRecord.due_amount,
      'new_discount': this.selectedRecord.discount,
      'new_payment_option': this.selectedRecord.payment_option,
      'new_expiry_date': this.selectedRecord.date_of_expire,
    });
  }
  patchConsultation() {
    this.editConsultationForm.patchValue({
      consult_patient_id: this.selectedRecord.patient_id,
      consult_patient_unique_id: this.patientid,
      consult_edit_for: this.selectedType,
      consult_id: this.selectedRecord.id,
      old_consult_fee: this.selectedRecord.consultation_fee,
      new_consult_fee: this.selectedRecord.consultation_fee,
      old_cc_payment_option: this.selectedRecord.payment_option,
      new_cc_payment_option: this.selectedRecord.payment_option,

    });
  }
  updateBilling(){
    this.editPrescriptionForm.markAllAsTouched();

    // console.log(this.editPrescriptionForm.value);
    if (this.editPrescriptionForm.valid) {
      this.filterLoading.next(true);
      // this.editPrescriptionForm.value.id = this.selectedRecord.id;
      this._service.updateBilling(this.editPrescriptionForm.value)
        .pipe(finalize(() => this.filterLoading.next(false)))
        .subscribe(res => {

          console.log(res);
          if (res['status'] == 'success') {
            this.editPrescriptionForm.reset();
            this.notifyService.showSuccess(res['message'], res['title']);
            this.modalClose();
            console.log(res['data']);
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          } 
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });

        // setInterval(function () {
        //   location.reload();
        // }, 3000);
    }
  }
  updateConsultation(){
    this.editConsultationForm.markAllAsTouched();

    if (this.editConsultationForm.valid) {
      this.filterLoading.next(true);
      this._service.updateConsult(this.editConsultationForm.value)
        .pipe(finalize(() => this.filterLoading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.editConsultationForm.reset();
            this.notifyService.showSuccess(res['message'], res['title']);
            this.modalClose();
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
        setInterval(function () {
          location.reload();
        }, 3000);
    }
  }
  /* Paginate records */
  // onFooterPage($event) {
  //   this.searchPatientForm.value.page = $event.page;
  //   this.searchPatients();
  // }

  // /* Manage Listing Limit */
  // listingLimit($event) {
  //   this.basicSelectedOption = $event;
  //   this.searchPatientForm.value.perpage = this.basicSelectedOption;
  //   this.searchPatientForm.value.page = 1;
  //   this.searchPatients();
  // }
   /* Reset Filter */
   resetFilter() {
    this.isShown = false;
    this.searchPatientForm.reset();
  }
  caseSheet(patientId) {
    let link = environment.subdirUrl + '/gm/home/case-sheet/' + patientId;
    this._router.navigate([]).then(result => { window.open(link, '_blank'); });
  }
  modalClose() {
    this.modalRef.close();
    this.editPrescriptionForm.reset();
  }
  allowNumbersOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode != "0") {
      event.preventDefault();
    }
  }

}
