import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router';

import 'hammerjs'
import { HrComponent } from '@hr/hr.component';
import { FrontDeskComponent } from '@foe/front-desk.component';
import { DoctorComponent } from '@doc/doctor.component';
import { ProComponent } from '@pro/pro.component';
import { PharmacyComponent } from '@pharmacy/pharmacy.component';
import { CallcenterComponent } from '@callcenter/callcenter.component';
import { AccountsComponent } from '@accounts/accounts.component';
import { StoreComponent } from '@store/store.component';
import { PolineComponent } from './poline/poline.component';
import { GmComponent } from './modules/gm/gm.component';
import { AuditComponent } from './modules/audit/audit.component';
import { DrcordinatorComponent } from './modules/drcordinator/drcordinator.component';
import { AgmComponent } from './modules/agm/agm.component';

const routes: Routes = [
  {
    path: '', component: PolineComponent, pathMatch: 'full'
  },
  {
    path: 'provitale', component: PolineComponent
  },
  {
    path: 'hr', component: HrComponent,
    loadChildren: () => import('./modules/hr/hr.module').then(m => m.HrModule)
  },
  {
    path: 'foe', component: FrontDeskComponent,
    loadChildren: () => import('./modules/front-desk/front-desk.module').then(m => m.FrontDeskModule)
  },
  {
    path: 'doctor', component: DoctorComponent,
    loadChildren: () => import('./modules/doctor/doctor.module').then(m => m.DoctorModule)
  },
  {
    path: 'pro', component: ProComponent,
    loadChildren: () => import('./modules/pro/pro.module').then(m => m.ProModule)
  },
  {
    path: 'pharmacy', component: PharmacyComponent,
    loadChildren: () => import('./modules/pharmacy/pharmacy.module').then(m => m.PharmacyModule)
  },
  { 
    path: 'callcenter', component: CallcenterComponent,
    loadChildren: () => import('./modules/callcenter/callcenter.module').then(m => m.CallcenterModule) 
  },
  {
    path: 'accounts', component: AccountsComponent,
    loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'store', component: StoreComponent,
    loadChildren: () => import('./modules/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'audit', component: AuditComponent,
    loadChildren: () => import('./modules/audit/audit.module').then(m => m.AuditModule)
  },
  {
    path: 'drcordinator', component: DrcordinatorComponent,
    loadChildren: () => import('./modules/drcordinator/drcordinator.module').then(m => m.DrcordinatorModule)
  },
  {
    path: 'gm', component: GmComponent,
    loadChildren: () => import('./modules/gm/gm.module').then(m => m.GmModule)
  },
  {
    path: 'agm', component: AgmComponent,
    loadChildren: () => import('./modules/agm/agm.module').then(m => m.AgmModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }